


























































































































































































































































import DialogoDeEdicaoDeFornecedor from '@/components/fornecedor/DialogoDeEdicaoDeFornecedor.vue'
import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import { ImportacaoDeNota } from '@/models/ImportacaoDeNota'
import { formatarCnpjOuCpf } from '@/shareds/formatadores'
import { obrigatorio } from '@/shareds/regras-de-form'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FinalizarImportacaoDeNotaDoFornecedorUseCase, ImportarNotaDoFornecedorUseCase } from '@/usecases'
import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import ProdutoDaImportacaoDeNota from "./ProdutoDaImportacaoDeNota.vue"
import SeletorDeMarca from '@/components/produto/SeletorDeMarca.vue'
import { ProdutoPadraoImportacao } from '@/models'
import SeletorDeTag from '@/components/produto/SeletorDeTag.vue'
import SeletorDeCategoria from '../produtos/EdicaoDeProduto/SeletorDeCategoria.vue'
import SeletorDeEstacao from '@/components/produto/SeletorDeEstacao.vue'
import SeletorDeGenero from '@/components/produto/SeletorDeGenero.vue'
import { round } from '@/shareds/number-utils'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import { uploadFilesToS3 } from '@/shareds/s3/files'

const IMPORTACAO_DE_NOTA = 'IMPORTACAO_DE_NOTA'

@Component({
	components: {
		BuscaDeProdutoDropdown,
		DialogoDeEdicaoDeFornecedor,
		ProdutoDaImportacaoDeNota,
		SeletorDeMarca,
		SeletorDeTag,
		SeletorDeCategoria,
		SeletorDeEstacao,
		SeletorDeGenero,
		CampoDePercentual,
	},
})
export default class TelaDeImportacaoDeNota extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() dialogoDeFornecedor!: DialogoDeEdicaoDeFornecedor

	AlertModule = AlertModule

	importarNotaDoFornecedorUseCase = new ImportarNotaDoFornecedorUseCase()
	finalizarImportacaoDeNotaDoFornecedorUseCase = new FinalizarImportacaoDeNotaDoFornecedorUseCase()

	formatarCnpjOuCpf = formatarCnpjOuCpf
	obrigatorio = obrigatorio
	file: File | null = null
	carregando = false
	criandoFornecedor = false
	enviando = false
	importacaoDeNota: ImportacaoDeNota | null = localStorage[IMPORTACAO_DE_NOTA]
		? JSON.parse(localStorage[IMPORTACAO_DE_NOTA])
		: null
	mostrar = false
	produtoNovo: ProdutoPadraoImportacao = {} as ProdutoPadraoImportacao
	indiceEmEdicao = 0

	async importarNota() {
		try {
			this.carregando = true
			this.importacaoDeNota = await this.importarNotaDoFornecedorUseCase.execute(
				this.file,
				(fornecedor) => this.dialogoDeFornecedor.mostrar(fornecedor, true),
			)

			this.importacaoDeNota.produtos.forEach(produto => {
				if (produto.encontrado && !produto.encontrado.id && this.importacaoDeNota) {
					produto.encontrado.marca = this.importacaoDeNota.fornecedor.encontrado?.marca || null
				}
			})
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	get tiposDeProdutos(): BuscaDeProdutoDropdown['filtros'] {
		return { tipos: ['Padrão', 'Variante', 'Composto'] }
	}

	async salvarMapeamentoDeNotas() {
		if (!this.importacaoDeNota) return;
		if (this.importacaoDeNota.produtos.some(({ encontrado }) => !encontrado)) {
			AlertModule.setError('Existem produtos não associados');
			return;
		}
		if (!this.form.validate()) return;
		try {
			this.enviando = true;

			const importacaoDeNotaBlob = new Blob([JSON.stringify(this.importacaoDeNota)], { type: 'application/json' });
			const importacaoDeNotaFile = new File([importacaoDeNotaBlob], 'importacaoDeNota.json', { type: 'application/json' });

			const informacoesDosArquivos = await uploadFilesToS3(
				[importacaoDeNotaFile],
				`importacao-de-notas`,
			);
			const urlDoArquivoJson = informacoesDosArquivos.map(({ config }) => config.url)[0];

			if (!urlDoArquivoJson) {
				return AlertModule.setError("Ocorreu um erro interno, contate o suporte");
			}

			const importacaoDeNotaFinalizada = await this.finalizarImportacaoDeNotaDoFornecedorUseCase.execute(urlDoArquivoJson);

			if (!importacaoDeNotaFinalizada.feedbackDosTitulos) {
				AlertModule.setSuccess('Nota importada com sucesso');
			} else {
				AlertModule.setInfo(`Nota importada com sucesso, porém ${importacaoDeNotaFinalizada.feedbackDosTitulos}`);
			}

			this.importacaoDeNota = null;
		} catch (error) {
			AlertModule.setError(error);
		} finally {
			this.enviando = false;
		}
	}

	limparIntegracaoDeNota() {
		localStorage.removeItem(IMPORTACAO_DE_NOTA)
		this.importacaoDeNota = null
	}

	async editarFornecedor() {
		if (!this.importacaoDeNota || !this.importacaoDeNota.fornecedor.encontrado) return
		const fornecedor = await this.dialogoDeFornecedor.mostrar(this.importacaoDeNota.fornecedor.encontrado, false)
		if (!fornecedor) return
		this.importacaoDeNota = {
			...this.importacaoDeNota,
			fornecedor: {
				...this.importacaoDeNota.fornecedor,
				encontrado: fornecedor,
			},
		}
	}

	@Watch('importacaoDeNota', { deep: true })
	onChangeIntegracaoDeNota() {
		this.file = null
		localStorage.setItem(IMPORTACAO_DE_NOTA, JSON.stringify(this.importacaoDeNota))
	}

	abrirDialogo(produtoNovo: ProdutoPadraoImportacao, indice: number) {
		this.produtoNovo = produtoNovo
		this.indiceEmEdicao = indice

		if (!produtoNovo.precoCompra) {
			produtoNovo.precoCompra = produtoNovo.preco
			produtoNovo.margemLucro = 0
		}

		this.mostrar = true
	}

	salvarProduto() {
		if (!this.importacaoDeNota) return

		this.importacaoDeNota.produtos[this.indiceEmEdicao].encontrado = this.produtoNovo

		this.fecharDialogo()
	}

	fecharDialogo() {
		this.produtoNovo = {} as ProdutoPadraoImportacao
		this.indiceEmEdicao = 0
		this.mostrar = false
	}

	calculaPrecoVarejo() {
		if (!this.produtoNovo) return
		const precoCompra = this.produtoNovo.precoCompra || 0
		const margemDeLucro = 1 + (this.produtoNovo.margemLucro || 0) / 100
		
		this.produtoNovo.preco = round(
			(precoCompra * margemDeLucro ).toString(),
			2,
		)
	}
}

