























































import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import { ProdutoDaImportacao } from '@/models/ImportacaoDeNota'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { obrigatorio } from '@/shareds/regras-de-form'
import { fixarCasasDecimais } from '@/shareds/formatadores'
import { LojaDoPdv } from '@/models'

@Component({
	components: {
		BuscaDeProdutoDropdown,
	},
})

export default class ProdutoDaImportacaoDeNota extends Vue {
	@Prop() produto!: ProdutoDaImportacao
	@Prop() indice!: number
	@Prop() loja!: LojaDoPdv
	obrigatorio = obrigatorio
	fixarCasasDecimais = fixarCasasDecimais
  
	get tiposDeProdutos(): BuscaDeProdutoDropdown['filtros'] {
		return { tipos: ['Padrão', 'Variante', 'Composto'] }
	}

	formatarNumero(numero) {
		if(numero == null || numero == undefined) {
			return ''
		}
		return this.fixarCasasDecimais(parseFloat(numero), 2)
	}
	
	editarNovoProduto() {
		this.$emit('abrirDialogo', this.produto.encontrado, this.indice)
	}
}

